<template>
	<v-autocomplete
		v-model="model"
		:items="items"
		:label="input.label"
		:disabled="header.disabled"
		:placeholder="header.note || ''"
		item-value="id"
		item-text="name"
		hide-details
		clearable
		outlined
	>
	</v-autocomplete>
</template>
<script>
import { mapActions } from 'vuex';
export default {
	name: 'CAutocomplete',
	props: {
		input: Object,
	},
	data: () => ({}),
	computed: {
		header() {
			return this.$store.getters['incident/getHeaderById'](this.input.id);
		},
		items() {
			return this.formattingItems(this.input?.key) || [];
		},
		model: {
			get() {
				return parseInt(this.header?.value) || null;
			},
			set(value) {
				this.$store.dispatch('incident/updateInputHeader', {
					value,
					id: this.input.id,
				});
			},
		},
	},
	created() {
		this.switchActions();
		if (this.input.key == 'user_reporting' && !this.input.value) {
			this.model = this.$store.state.auth.user.id;
		}
	},
	methods: {
		...mapActions('security', ['getManagements', 'getUsersActive']),
		formattingItems(key = '') {
			const costCenters = this.$store.state.security.costCenters;
			const usersActive = this.$store.getters['security/getUsers'];
			let arrItems = [];
			switch (key) {
				case 'service':
					arrItems = costCenters;
					break;
				case 'client':
				case 'manager':
				case 'user_visit':
				case 'companion_user_visit':
				case 'user_affected':
				case 'user_reporting':
					arrItems = usersActive;
					break;
				default:
					arrItems = [];
			}
			return arrItems || [];
		},
		switchActions() {
			//const user_id = this.$store.state.auth.user.id;
			switch (this.input.key) {
				case 'service':
					// this.getManagements({
					// 	params: { user_id: user_id },
					// });
					break;
				case 'client':
				case 'manager':
				case 'user_visit':
				case 'companion_user_visit':
				case 'user_affected':
					this.getUsersActive();
					break;
				case 'user_reporting':
					if (this.header?.value) {
						this.getManagements({
							params: { user_id: this.header?.value },
						});
					}
					this.getUsersActive();
					break;
			}
		},
	},
};
</script>
